<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="modal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-i18n="adOns">Configure SMS Ad-Ons</span>
    </template>
    <template v-slot>
      <div>
        <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
          <UiInput
            v-model="smsConfigData.title"
            type="text"
            name="TITLE"
            title="TITLE"
            label="TITLE"
            placeholder="TITLE"
            class="flex-1 w-full"
            rules="required"
          />
          <UiInput
            v-model="smsConfigData.url"
            type="text"
            name="URL"
            title="URL"
            label="URL"
            placeholder="URL"
            class="flex-1 w-full"
            rules="required"
          />
        </div>
        <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
          <UiInput
            v-model="smsConfigData.to_key"
            type="text"
            name="TO"
            title="TO"
            label="TO"
            placeholder="TO"
            class="flex-1 w-full"
            rules="required"
          />
          <UiInput
            v-model="smsConfigData.message_key"
            type="text"
            name="MESSAGE"
            title="MESSAGE"
            label="MESSAGE"
            placeholder="MESSAGE"
            class="flex-1 w-full"
            rules="required"
          />
        </div>
        <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
          <UiSingleSelect
            v-model="smsConfigData.request_type"
            title="REQUEST_TYPE"
            label="title"
            class="flex-1"
            :options="requestTypesList"
            rules="required"
          />
        </div>
        <Divider />
        <!-- Headers -->
        <div class="flex items-center justify-between">
          <Heading heading="Headers" />
          <span class="sm:self-center self-end" @click="addNewKeyPairInList(headersList)">
            <icon
              class="icon cursor-pointer"
              width="18"
              height="18"
              color="primary-green"
              icon="plus-circle"
            />
          </span>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div>
          <div
            v-for="(header, index) in headersList"
            :key="index"
            class="flex flex-col sm:flex-row gap-4 lg:gap-5"
            @mouseover="toggleListCrossButton(headersList, index, true)"
            @mouseleave="toggleListCrossButton(headersList, index, false)"
          >
            <UiInput
              v-model="headersList[index].key"
              type="text"
              name="KEY"
              title="KEY"
              label="KEY"
              placeholder="KEY"
              class="flex-1"
            />
            <div class="flex flex-1 gap-3 flex-col sm:flex-row">
              <UiInput
                v-model="headersList[index].value"
                type="text"
                name="VALUE"
                title="VALUE"
                label="VALUE"
                placeholder="ENTER_VALUE"
                class="flex-1"
              />
            </div>
            <span
              v-if="headersList.length > 1 && header.isCrossDisplay"
              class="sm:self-center self-end"
              @click="removeKeyPairItemFromList(headersList, index)"
            >
              <icon
                class="icon cursor-pointer"
                width="12"
                height="12"
                color="mahroon-strong"
                icon="cross"
              />
            </span>
          </div>
        </div>
        <!-- </ValidationObserver> -->
        <Divider />
        <!-- Params -->
        <div class="flex items-center justify-between">
          <Heading heading="Params" />
          <span class="sm:self-center self-end" @click="addNewKeyPairInList(paramsList)">
            <icon
              class="icon cursor-pointer"
              width="18"
              height="18"
              color="primary-green"
              icon="plus-circle"
            />
          </span>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div>
          <div
            v-for="(param, index) in paramsList"
            :key="index"
            class="flex flex-col sm:flex-row gap-4 lg:gap-5"
            @mouseover="toggleListCrossButton(paramsList, index, true)"
            @mouseleave="toggleListCrossButton(paramsList, index, false)"
          >
            <UiInput
              v-model="paramsList[index].key"
              type="text"
              name="KEY"
              title="KEY"
              label="KEY"
              placeholder="KEY"
              class="flex-1"
            />
            <div class="flex flex-1 gap-3 flex-col sm:flex-row">
              <UiInput
                v-model="paramsList[index].value"
                type="text"
                name="VALUE"
                title="VALUE"
                label="VALUE"
                placeholder="ENTER_VALUE"
                class="flex-1"
              />
              <span
                v-if="paramsList.length > 1 && param.isCrossDisplay"
                class="sm:self-center self-end"
                @click="removeKeyPairItemFromList(paramsList, index)"
              >
                <icon
                  class="icon cursor-pointer"
                  width="12"
                  height="12"
                  color="mahroon-strong"
                  icon="cross"
                />
              </span>
            </div>
          </div>
        </div>
        <!-- </ValidationObserver> -->

        <Divider />
        <!-- Secrets Headers -->
        <div class="flex items-center justify-between">
          <Heading heading="Secrets Headers" />
          <span class="sm:self-center self-end" @click="addNewKeyPairInList(secretsHeadersList)">
            <icon
              class="icon cursor-pointer"
              width="18"
              height="18"
              color="primary-green"
              icon="plus-circle"
            />
          </span>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div>
          <div
            v-for="(header, index) in secretsHeadersList"
            :key="index"
            class="flex flex-col sm:flex-row gap-4 lg:gap-5"
            @mouseover="toggleListCrossButton(secretsHeadersList, index, true)"
            @mouseleave="toggleListCrossButton(secretsHeadersList, index, false)"
          >
            <UiInput
              v-model="secretsHeadersList[index].key"
              type="text"
              name="KEY"
              title="KEY"
              label="KEY"
              placeholder="KEY"
              class="flex-1"
            />
            <div class="flex flex-1 gap-3 flex-col sm:flex-row">
              <UiInput
                v-model="secretsHeadersList[index].modelValuevalue"
                type="text"
                name="VALUE"
                title="VALUE"
                label="VALUE"
                placeholder="ENTER_VALUE"
                class="flex-1"
              />
              <span
                v-if="secretsHeadersList.length > 1 && header.isCrossDisplay"
                class="sm:self-center self-end"
                @click="removeKeyPairItemFromList(secretsHeadersList, index)"
              >
                <icon
                  class="icon cursor-pointer"
                  width="12"
                  height="12"
                  color="mahroon-strong"
                  icon="cross"
                />
              </span>
            </div>
          </div>
        </div>
        <!-- </ValidationObserver> -->

        <Divider />
        <!-- Secrets Params -->
        <div class="flex items-center justify-between">
          <Heading heading="Secrets Params" />
          <span class="sm:self-center self-end" @click="addNewKeyPairInList(secretsParamsList)">
            <icon
              class="icon cursor-pointer"
              width="18"
              height="18"
              color="primary-green"
              icon="plus-circle"
            />
          </span>
        </div>
        <!-- <ValidationObserver tag="div"> -->
        <div>
          <div
            v-for="(param, index) in secretsParamsList"
            :key="index"
            class="flex flex-col sm:flex-row gap-4 lg:gap-5"
            @mouseover="toggleListCrossButton(secretsParamsList, index, true)"
            @mouseleave="toggleListCrossButton(secretsParamsList, index, false)"
          >
            <UiInput
              v-model="secretsParamsList[index].key"
              type="text"
              name="KEY"
              title="KEY"
              label="KEY"
              placeholder="KEY"
              class="flex-1"
            />
            <div class="flex flex-1 gap-3 flex-col sm:flex-row">
              <UiInput
                v-model="secretsParamsList[index].value"
                type="text"
                name="VALUE"
                title="VALUE"
                label="VALUE"
                placeholder="ENTER_VALUE"
                class="flex-1"
              />
              <span
                v-if="secretsParamsList.length > 1 && param.isCrossDisplay"
                class="sm:self-center self-end"
                @click="removeKeyPairItemFromList(secretsParamsList, index)"
              >
                <icon
                  class="icon cursor-pointer"
                  width="12"
                  height="12"
                  color="mahroon-strong"
                  icon="cross"
                />
              </span>
            </div>
          </div>
        </div>
        <!-- </ValidationObserver> -->
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="adOns"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        :disabled="isLoading"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(configureSMSProvider(invalid))"
      >
        <span v-if="isLoading">
          <Loader />
        </span>
        <span v-else v-i18n="adOns">
          <span>Add</span>
        </span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiInput from '@components/UiElements/UiInputBox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import icon from '@components/icons/icon.vue'
import Heading from '@src/components/Heading.vue'
import Divider from '@src/components/Divider.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import { mapActions } from 'vuex'

export default {
  components: {
    // ValidationObserver,
    UiModalContainer,
    UiSingleSelect,
    UiInput,
    Loader,
    icon,
    Heading,
    Divider,
  },
  props: {
    modal: { type: Boolean, default: false },
  },
  emits: ['toggle'],
  data() {
    return {
      adOns: 'adOns',
      isLoading: false,
      smsConfigData: {
        headers: {},
        params: {},
        params_secrets: {},
        headers_secrets: {},
        title: '',
        to_key: '',
        message_key: '',
        request_type: '',
        url: '',
      },
      headersList: [{ key: '', value: '', isCrossDisplay: false }],
      paramsList: [{ key: '', value: '', isCrossDisplay: false }],
      secretsHeadersList: [{ key: '', value: '', isCrossDisplay: false }],
      secretsParamsList: [{ key: '', value: '', isCrossDisplay: false }],
      requestTypesList: ['POST', 'GET'],
      newKeyPairItem: { key: '', value: '', isCrossDisplay: false },
    }
  },
  methods: {
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },
    addNewKeyPairInList(list) {
      let newItem = objectDeepCopy(this.newKeyPairItem)
      list.push(newItem)
    },
    removeKeyPairItemFromList(list, idx) {
      list.splice(idx, 1)
    },
    toggleListCrossButton(list, idx, value) {
      list[idx].isCrossDisplay = value
    },

    configureSMSProvider(invalid) {
      if (invalid) return
      let payload = { ...this.smsConfigData, ...this.getConfigurationPayload() }
      this.addNewSMSConfiguration(payload).then(() => {
        this.$emit('toggle', true)
      })
    },
    getConfigurationPayload() {
      return {
        headers: this.reduceHeaderList(),
        params: this.reduceParamsList(),
        params_secrets: this.reduceSecretParamsList(),
        headers_secrets: this.reduceSecrectHeaderList(),
      }
    },
    reduceHeaderList() {
      let headers = {}
      this.headersList.forEach((header) => {
        if (header.key) headers = { ...headers, ...{ [header.key]: header.value } }
      })
      return headers
    },
    reduceParamsList() {
      let params = {}
      this.paramsList.forEach((param) => {
        if (param.key) params = { ...params, ...{ [param.key]: param.value } }
      })
      return params
    },
    reduceSecrectHeaderList() {
      let headers = {}
      this.secretsHeadersList.forEach((header) => {
        if (header.key) headers = { ...headers, ...{ [header.key]: header.value } }
      })
      return headers
    },
    reduceSecretParamsList() {
      let params = {}
      this.secretsParamsList.forEach((param) => {
        if (param.key) params = { ...params, ...{ [param.key]: param.value } }
      })
      return params
    },
    ...mapActions('addons', ['addNewSMSConfiguration']),
  },
}
</script>
